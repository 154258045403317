'use strict';

export const hero = (() => {

    const init = () => {
        setTimeout(function() {
            toggleClass();
        }, 10000);

        setInterval(function() {
            toggleClass();
        }, 20000);
    };

    const toggleClass = () => {
        $('body').removeClass('is-displayed');
        setTimeout(function() {
            $('body').addClass('is-displayed');
        }, 2000);
    };

    return {
        init
    };
})();
