'use strict';

export const accordion = (() => {

    const init = () => {
        $('.js-accordion').on('click', event => {
            const $button = $(event.currentTarget);
            const $item = $button.next('.js-accordion-item');
            const $background = $button.prev('.js-accordion-background');

            $button.toggleClass('is-open');
            $background.toggleClass('is-open');
            $item.toggleClass('is-open').slideToggle(200);
        });

        $('.js-accordion-background').on('click', event => {
            const $background = $(event.currentTarget);
            const $button = $background.next('.js-accordion');
            const $item = $button.next('.js-accordion-item');

            $button.toggleClass('is-open');
            $background.toggleClass('is-open');
            $item.toggleClass('is-open').slideToggle(200);
        });

        $('.js-nav-item a').on('click', event => {
            const $button = $('.js-accordion');
            const $background = $('.js-accordion-background');
            const $item = $('.js-nav-item');

            $button.toggleClass('is-open');
            $background.toggleClass('is-open');
            $item.toggleClass('is-open').slideToggle(200);
        });
    };

    return {
        init: init
    };
})();
