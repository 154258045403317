'use strict';

import slick from 'slick-carousel';

export const carousel = (() => {

    /**
     * init
     */
    const init = () => {
        $('.js-carousel').slick({
            autoplay: true,
            autoplaySpeed: 2000,
			arrows: true,
            variableWidth: true,
            infinite: true
        });
    }

    // public
    return {
        init: init
    };
})();
