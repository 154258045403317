'use strict';


export const tag = (() => {

    const init = () => {
        $('.js-tag').on('click', event => {
            const $this = $(event.currentTarget);
            const tag = $this.data('tag');
            const $tagItem = $('.list-index__item');
            $tagItem.fadeOut(200);

            setTimeout(() => {
                $tagItem.each((index, element) => {
                    const $element = $(element);

                    if ($element.hasClass(`${tag}`)) {
                        $element.fadeIn(200);
                    }
                });
            }, 400);

            $('.js-accordion').trigger('click');

            $('.js-tag').removeClass('is-checked');
            $this.addClass('is-checked');
        });
    };

    return {
        init
    };
})();
