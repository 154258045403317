'use strict';

import { set } from "lodash";

export const job = (() => {

    const init = () => {

        $('#q1 input[type="radio"]').on('click', event => {
            $('#q1 .js-job').addClass('is-active');
        });

        $('.js-job').on('click', event => {
            const $this = $(event.currentTarget);
            const dataJob = $this.data('job');
            const $jobId = $('#' + dataJob);

            const $jobBody = $('.job-search__body');
            const $searching = $('#searching');
            const $resultText = $('.job-search__lead span');

            $jobBody.fadeOut(200);

            setTimeout(() => {
                $jobBody.removeClass('is-show');

                if(dataJob !== 'start') {
                    $('.job-search__wrapper').addClass('is-show');
                } else {
                    $('.job-search__wrapper').removeClass('is-show');
                }

                if(dataJob === 'q2') {
                    $('.job-search__middle').addClass('is-show');
                } else if(dataJob === 'start') {
                    $('.job-search__middle').removeClass('is-show');
                }

                if(dataJob === 'start') {
                    $('input[name="q"]').prop('checked', false);
                    $('#q1 .js-job').removeClass('is-active');
                }

                if(!dataJob.startsWith('a')) {
                    $jobId.fadeIn(200);
                    $jobId.addClass('is-show');
                } else {
                    $('.job-search__middle').removeClass('is-show');
                    $searching.fadeIn(200);
                    $searching.addClass('is-show');

                    const inputValue = $('input[name="q"]:checked').val();
                    $resultText.text(inputValue);

                    setTimeout(() => {
                        $searching.fadeOut(200);

                        setTimeout(() => {
                            $searching.removeClass('is-show');
                            $jobId.fadeIn(200);
                            $jobId.addClass('is-show');
                        }, 200);
                    }, 2000);
                }
            }, 200);
        });
    };

    return {
        init
    };
})();
