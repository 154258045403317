'use strict';

import 'waypoints/lib/jquery.waypoints.min';

export const inview = (() => {

    /**
     * init
     */
    const init = () => {
        $('.js-inview').each((index, element) => {
            const waypoint = new Waypoint({
                element: element,
                handler: direction => {
                    $(element).addClass('is-inview');
                },
                offset: $(element).data('offset') ? $(element).data('offset') : '50%'
            });
        });

        $(window).on('resize', () => {
            setTimeout(() => {
                refresh();
            }, 100);
        });
    };

    /**
     * refresh
     */
    const refresh = () => {
        Waypoint.refreshAll();
    };

    return {
        init: init,
        refresh: refresh
    };
})();
