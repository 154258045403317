'use strict';

export const modal = (() => {

    /**
     * init
     */
    const init = () => {

        $(document).on('click', '.js-show-modal', event => {
            event.preventDefault();

            const $this = $(event.currentTarget);
            const id = $this.data('modal-id');
            const $modal = $('#' + id);
            console.log($modal.length);

            show($modal);
        });

        $(document).on('click', '.js-hide-modal', event => {
            const $this = $(event.currentTarget);
            const id = $this.data('modal-id');
            const $modal = $('#' + id);

            hide($modal);
        });

		window.onbeforeunload = () => {
            movieControl('stop');
        };
    };

    /**
     * show
     */
    const show = ($modal, option) => {
        $modal.addClass('is-show');
        $modal.find('.modal__overlay').height($modal.find('.modal__body').height());
        $('body').addClass('is-show-modal');
        movieControl('start');
    };

    /**
     * hide
     */
    const hide = $modal => {
        movieControl('stop');
        $modal.removeClass('is-show');
        $('body').removeClass('is-show-modal');
    };

    /**
     * movieControl
     */
    const movieControl = ($action) => {
		$('.modal.is-show').find('iframe').each(function() {
			if ($action === 'start') {
				$(this).attr('src', $(this).attr('src') + '&autoplay=1');
			} else if ($action === 'stop') {
				$(this).attr('src', $(this).attr('src').replace('&autoplay=1', ''));
			}
		});
    };

    return {
        init: init
    };
})();
