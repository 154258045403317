'use strict';

import { scroll } from './_scroll';

export const nav = (() => {

    const init = () => {
        $('.js-toggle-nav').on('click', event => {
            toggle();
        });

        $('.js-hide-nav').on('click', event => {
            hide();
        });

        // $('.sns__item:last-of-type').hide();

        $('.js-share').on('click', event => {
            const $this = $(event.currentTarget);
            $this.parent().toggleClass('is-show');
            // $('.sns__item:last-of-type').fadeToggle();
        });
    };

    const toggle = () => {
        $('body').toggleClass('is-show-nav');

        if($('body').hasClass('is-show-nav')) {
            scroll.stop();
        }
        else {
            scroll.start();
        }
    };

    const show = () => {
        $('body').toggleClass('is-show-nav');
        scroll.stop();
    };

    const hide = () => {
        $('body').removeClass('is-show-nav');
        scroll.start();
    };

    return {
        init,
        hide,
        show,
    };
})();
