'use strict';

import Lenis from '@studio-freight/lenis';

export const scroll = (() => {

    let lenis;

    const init = () => {
    };

    const stop = () => {
    };

    const start = () => {
    };

    return {
        init,
        stop,
        start
    };
})();
