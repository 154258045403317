'use strict';

import SimpleBar from 'simplebar';

import { accordion } from './module/_accordion';
import { anchor } from './module/_anchor';
import { hover } from './module/_hover';
import { inview } from './module/_inview';
import { job } from './module/_job';
import { modal } from './module/_modal';
import { carousel } from './module/_carousel';
import { hero } from './module/_hero';
import { nav } from './module/_nav';
import { tag } from './module/_tag';

const main = (() => {
    /**
     * loaded
     */
    const loaded = () => {
        if(location.hash) {
            anchor.moveTo(null, location.hash, 0, () => {
                inview.init();
            });
        }
        else {
            inview.init();
        }

        const pathUrl = window.location.pathname;
        const currentUrl = pathUrl.endsWith('/') ? pathUrl.slice(0, -1) : pathUrl;

        $('.nav-child__item a').each(function() {
            const linkHref = $(this).attr('href');
            const linkUrl = linkHref.replace('..', '');

            if (currentUrl === linkUrl) {
                $(this).addClass('is-active');
            }
        });
    };

    /**
     * scrolled
     */
    const scrolled = () => {
        if($(window).scrollTop() >= $('.layout-header').outerHeight()) {
            $('body').addClass('is-scrolled');
        }
        else {
            $('body').removeClass('is-scrolled');
        }
    };

    /**
     * resized
     */
    const resized = () => {
        setStyleVarVW();
        setStyleVarVH();

        if($(window).width() > 768) {
            $('.js-simplebar').each((index, element) => {
                new SimpleBar(element);
            });
        }
    };

    /**
     * setStyleVarVH
     */
    const setStyleVarVH = () => {
        let vh = $(window).innerHeight() * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
    };

    /**
     * setStyleVarVW
     */
    const setStyleVarVW = () => {
        let vw = $(window).innerWidth() * 0.01;
        document.documentElement.style.setProperty('--vw', vw + 'px');
    };

    /**
     * init
     */
    const init = () => {
        $('body').addClass('is-loaded is-displayed');
        
        scrolled();
        resized();

        tag.init();
        accordion.init();
        anchor.init();
        hover.init();
        job.init();
        modal.init();
        carousel.init();
        hero.init();
        nav.init();

        if($(window).width() > 768) {
            $('.js-simplebar').each((index, element) => {
                new SimpleBar(element);
            });
        }

        $('.js-toggle-share').on('click', event => {
            $('.share').toggleClass('is-show');
        });

        $('a, button').not('.fb-xfbml-parse-ignore').addClass('js-hover');

        $('a[href]').each((index, element) => {
            const url = location.protocol + '//' + location.host;
            const href = $(element).attr('href');

            // 外部リンクはtarget="_blank"に
            if(!href.startsWith(url) && !href.startsWith('/') && !href.startsWith('.') && !href.startsWith('#')) {
                $(element).attr('target', '_blank').attr('rel', 'noopener noreferrer');
            }

            // 拡張子が該当する場合はtarget="_blank"に
            if(/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
                $(element).attr('target', '_blank').attr('rel', 'noopener noreferrer');
            }
        });
    };

    // public
    return {
        init,
        loaded,
        scrolled,
        resized,
    };
})();

$(() => {
    main.init();
});

$(window).on('load', () => {
    main.loaded();
});

$(window).on('scroll', () => {
    main.scrolled();
});

$(window).on('resize', () => {
    setTimeout(() => {
        main.resized();
    }, 100);
});
